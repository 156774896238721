export default class Contact 
{
	constructor(name, phone, address, email)
	{
		this.name = name;
		this.phone = phone;
		this.address = address;
		this.email = email;
	}

	parseJson = function(json){
		const obj = JSON.parse(json);

		this.name = (obj.name === null) ? '' : obj.name;
		this.phone = (obj.phone === null) ? '' : obj.phone;
		this.address = (obj.address === null) ? '' : obj.address;
		this.email = (obj.email === null) ? '' : obj.email;
	}
}