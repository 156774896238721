import Contact from './Contact.js';

export default class Invoice 
{
	constructor (type, template_logo, number, billDate, billTime, receiptDate, paid, businesses, customer, products,
				tasks, taxes, subTotal, taxTotal, total, comments, isTemplate, templateName,language, id = null)
	{
		this.type = (type === undefined || type === null) ? 0 : type;
		this.template_logo = (template_logo === undefined || template_logo === null) ? null : template_logo;
		this.number = (number === undefined || number === null) ? '' : number;
		this.billdate = (billDate === undefined || billDate === null) ? '' : billDate;
		this.billtime = (billTime === undefined || billTime === null) ? '' : billTime;
		this.receiptdate = (receiptDate === undefined || receiptDate === null) ? '' : receiptDate;
		this.paid = (paid === undefined || paid === null) ? false : paid;
		this.bussiness = (businesses === undefined || businesses === null) ? new Contact() : businesses;
		this.customer = (customer === undefined || customer === null) ? new Contact() : customer;
		this.products = (products === undefined || products === null) ? [] : products;
		this.tasks = (tasks === undefined || tasks === null) ? [] : tasks;
		this.taxes = (taxes === undefined || taxes === null) ? [] : taxes;
		this.subTotal = (subTotal === undefined || subTotal === null) ? 0.00 : subTotal;
		this.taxTotal = (taxTotal === undefined || taxTotal === null) ? 0.00 : taxTotal;
		this.total = (total === undefined || total === null) ? 0.00 : total;
		this.comments = (comments === undefined || comments === null) ? '' : comments;
		this.isTemplate = (isTemplate === undefined || isTemplate === null) ? false : isTemplate;
		this.templateName = (templateName === undefined || templateName === null) ? '' : templateName;
		this.id = (id === undefined || id === null) ? null : id;
		this.language = (language === undefined || language === null) ? null : language;
	}
}