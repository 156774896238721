import NotificationBus from "../../../../notification-bus";
import Notification from "../../../../models/Notification";
// import Invoice from "@/models/Invoice";

export default{
	mounted(){
		// console.log(JSON.parse(this.invoice.customer));

	},
	data(){
		return {
			url: '/invoice/details/' + this.invoiceProp.id,
			customer: this.invoiceProp.customer,
            invoice: this.invoiceProp
		}
	},
	props: ['invoiceProp'],
	methods:{
        deleteInvoice: function(invoiceId) {
            // let target = $(event.currentTarget.dataset.target);
            let uri = '/invoice/delete/' + invoiceId;
            let headers = {
                // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                'Content-Type': 'application/json;multipart/form-data',
                'Accept': 'application/pdf;application/octet-stream'
            };
            this.$http.delete(uri, {headers: headers})
                .then((res) => {
                    // target.remove();
                    let notification = new Notification();
                    notification.id = 0;
                    notification.type = 'success';
                    notification.title = 'COMMON.SUCCESS';
                    notification.message = res.body.message;
                    NotificationBus.emitNotification(notification);
                }, () => {
                    let notification = new Notification('','','',0);
                    notification.id = 0;
                    notification.type = 'danger';
                    notification.title = 'COMMON.ERROR';
                    notification.message = 'INTERNAL.SERVER.ERROR';
                    NotificationBus.emitNotification(notification);
                })
        },

        editInvoice: function(invoiceId){
            location.assign(`/invoice/edit/${invoiceId}`);
        }
    }
}