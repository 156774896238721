'use strict'
import profilePicturePanel from './subcomponents/profilePicturePanel/profilePicturePanel.vue';
// import creditcardform from './subcomponents/creditCard/creditCard.vue';
// import autorenewbutton from './subcomponents/autorenewButton/autorenewButton.vue';
// import cardlist from './subcomponents/cardList/cardList.vue';
import profilePictureModal from './subcomponents/modals/profilePictureModal/profilePictureModal.vue';
import invoicelistContainer from './subcomponents/invoiceListContainer/invoiceListContainer.vue';
import NotificationBus from '@/notification-bus';
import Notification from '@/models/Notification';
import User from '@/models/User.js'
import editUserInfoModal from '@/components/modals/editProfileInfo/editProfileInfo.vue'

export default {
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.$setAuthCode();
			if(vm.$authCode === undefined || vm.$authCode === null){
				next('/login');
			}
		});
	},
	beforeRouteUpdate(to, from, next) {
		if(this.$authCode === undefined || this.$authCode === null){
			next('/login');
		}
	},
	mounted(){
		this.user = JSON.parse(atob(this.$getCookieValue('user')));
		this.oldEmail = this.user.email;
		this.show = true;
		this.$setAuthCode();
	},
	components:{
		profilePicturePanel,
		invoicelistContainer,
		profilePictureModal,
		editUserInfoModal,
	},
	data(){
		return {
			show: false,
			user: new User(),
			templates: [],
			subscriptions: [],
			newPictureSize: {},
			cards:[{
				name: 'Brian',
				last4: '4242',
				exp_month: '06',
				exp_year: '2025',
				brand: 'visa'
			}],
			// defaultLogo: '',
			maxImgWith: 350,
			defaultLogo: {},
			editingName: false,
			editingEmail: false,
			savingName: false,
			savingEmail: false,
			oldEmail: ''
		}
	},
	props: [],
	methods: {
		/**
		 *
		 * @param pictureSizeEmitted
		 */
		changePictureSize: function(pictureSizeEmitted){
			this.newPictureSize = pictureSizeEmitted;
		},
		/**
		 *
		 */
		chooseLogo: function(){
			// $('#default-logo-chooser').trigger('click');
		},
		/**
		 *
		 */
		getDefaultLogoDataURL(){
			// console.log(this.$authCode)
			let url = this.$apiUrl + '/profile/picture/get/' + this.user.id;
			this.$http.get(url, { 
				headers: {
					'Authorization': 'Bearer ' + this.$authCode,
					'Content-Type': 'application/json;multipart/form-data',
					'Accept': 'application/pdf;application/octet-stream'
				}
			}).then(response => {
				if(response.body.succeeded){
					this.defaultLogo = response.body.data.file;
					this.setNewLogo();
				} else {
					NotificationBus.$emit('eventFlashMessage', {
						id:0,
						type: 'danger',
						title: 'Error',
						message: response.body.message
					});
				}
			}).catch(() => {
				//console.log(error)
				NotificationBus.$emit('eventFlashMessage', {
					id:0,
					type: 'danger',
					title: 'Error',
					message: 'INTERNAL_ERROR'
				});
			})
		},
		/**
		 *
		 * @param event
		 */
		setNewLogo: function(event = null){
			const fileReader = new FileReader();
			if(event != null){
				fileReader.readAsDataURL(event.target.files[0]);
			} else {
				let byteString = atob(this.defaultLogo.split(',')[1])
				let buffer = new ArrayBuffer(byteString.length);
				let byteArray = new Uint8Array(buffer);
				for(let i = 0;i < byteString.length; i++){
					byteArray[i] = byteString.charCodeAt(i)
				}
				fileReader.readAsDataURL(new Blob([buffer], {type:'image/jpeg'}));
			}

			fileReader.onload = (function(invoice, http){
				return function(event){
					let i = new Image();
					i.onload = function(){
						let ar = i.width/i.height;
						if(ar > 1){
							if(i.width > this.maxImgWith)
								i.width = this.maxImgWith;
							i.height = i.width / ar;
						}
						else if(ar < 1){
							if(i.height > this.maxImgWith)
								i.height = this.maxImgWith;
						}
						else {
							i.width = this.maxImgWith;
							i.height = this.maxImgWith;
						}
					}
					i.src = event.target.result;
					i.remove();
					http.post('/profile/picture/set', {logo: event.target.result},{
						headers: {
							'Authorization': 'Bearer ' + this.$authCode,
							'Content-Type': 'application/json;multipart/form-data',
							'Accept': 'application/pdf;application/octet-stream'
						}
					}).then((success) => {
							this.defaultLogo = success.body.newImg;
						}, () => {
							//console.log(fail)
						})
				};
			}) (this.invoice, this.$http);
		},
		/**
		 *
		 */
		createNewTemplate: function() {
			this.$bvModal.show('payment-modal-modal');
		},
		/**
		 *
		 * @param templateId
		 */
		goToTemplate: function(templateId){
			this.$router.push(`/template/update/${templateId}`);
		},
		/**
		 *
		 * @param templateId
		 * @param index
		 */
		deleteTemplate: function(templateId, index){
			let notification = new Notification();
			this.$http.delete(this.$apiUrl + `/template/delete/${templateId}`, { 
				headers: {
					'Authorization': 'Bearer ' + this.$authCode,
					'Content-Type': 'application/json;multipart/form-data',
					'Accept': 'application/pdf;application/octet-stream'
				}
			}).then(() => {
				this.templates.splice(index, 1);
				notification.id = 0;
				notification.type = 'success';
				notification.title = 'COMMON.SUCCESS';
				notification.message = 'TEMPLATE.DELETE.SUCCESS';
				NotificationBus.emitNotification(notification);
			}).catch(() => {
				notification.id = 0;
				notification.type = 'danger';
				notification.title = 'COMMON.ERROR';
				notification.message = 'TEMPLATE.DELETE.FAIL';
				NotificationBus.emitNotification(notification);
			});
		},
		/**
		 *
		 * @param templateUri
		 * @param templateId
		 */
		getTemplateDataUrl: function(templateUri, templateId){
			let uri = this.$apiUrl + `/template/logo/get/${templateUri.replace(/\//g,'-')}`;
			this.$http.get(uri, { 
				headers: {
					'Authorization': 'Bearer ' + this.$authCode,
					'Content-Type': 'application/json;multipart/form-data',
					'Accept': 'application/pdf;application/octet-stream'
				}
			}).then(response => {
				let template = this.templates.find(t => { return t.id == templateId })
				template.template_logo = response.body.data.file;
			}).catch(() => {
				//console.log(error);
			});
		},
		/**
		 *
		 */
		getUserInfo(){
			let notification = new Notification();
			this.$http.get(this.$apiUrl + `/api/user/getByEmail/${this.user.email}`, {
				headers: {
					'Authorization': 'Bearer ' + this.$authCode,
					'Content-Type': 'application/json;multipart/form-data',
					'Accept': 'application/pdf;application/octet-stream'
				}
			}).then(() => {
				//console.log(response)
				// this.templates.splice(index, 1);
				notification.id = 0;
				notification.type = 'success';
				notification.title = 'COMMON.SUCCESS';
				notification.message = 'TEMPLATE.DELETE.SUCCESS';
				NotificationBus.emitNotification(notification);
			}).catch(() => {
				notification.id = 0;
				notification.type = 'danger';
				notification.title = 'COMMON.ERROR';
				notification.message = 'TEMPLATE.DELETE.FAIL';
				NotificationBus.emitNotification(notification);
			});
		},
		/**
		 *
		 */
		getTemplates: function(){
            let uri = this.$apiUrl + '/template/get/byuserid/' + this.user.id;
            this.$http.get(uri, {
                headers: {
                    'Authorization': 'Bearer ' + this.authCode,
                    'Content-Type': 'application/json;multipart/form-data',
                    'Accept': 'application/pdf;application/octet-stream'
                }
            }).then(
                success => {
                    this.templates = success.body.data;
                    //console.log(this.templates[0])
                }, 
                // failed => {
                //     // console.log(failed.status)
                // }
            )
        },
		/**
		 *
		 */
		getSubscriptionHistory() {
			let uri = this.$apiUrl + '/subscription/get/byuserid/' + this.user.id;
            this.$http.get(uri, {
                headers: {
                    'Authorization': 'Bearer ' + this.authCode,
                    'Content-Type': 'application/json;multipart/form-data',
                    'Accept': 'application/pdf;application/octet-stream'
                }
            }).then(
                success => {
					this.subscriptions = success.body.data;
                    //console.log(success.body.data)
                }, 
                // failed => {
                //     // console.log(failed.status)
                // }
            )
        },
		/**
		 *
		 */
		openEditUserInfoModal: function () {
			this.$bvModal.show('edit-user-info-modal');
		},
		/**
		 *
		 */
		toggleEditName: function () {
			Promise.resolve(this.editingName = !this.editingName).then(() => {
				if (this.editingName)
					this.$refs.profileUserName.querySelectorAll('input').forEach(e => {
						e.focus();
					});
			})
		},
		/**
		 *
		 */
		toggleEditEmail: function () {
			Promise.resolve(this.editingEmail = !this.editingEmail).then(() => {
				if (this.editingEmail)
					this.$refs.profileUserEmail.querySelectorAll('input').forEach(e => {
						e.focus();
					});
			})
		},
		/**
		 *
		 */
		updateName: function () {
			this.$refs.profileUserName.querySelectorAll('input, button').forEach(e => {
				e.setAttribute('readonly', true)
			})

			this.savingName = true;
			const url = this.$apiUrl + '/profile/updateName';
			let userID = JSON.parse(atob(this.$getCookieValue('user'))).id;
			//console.log(this.$authCode)
			const headers = {
				'Authorization': 'Bearer ' + this.$authCode,
				'Content-Type': 'application/json;multipart/form-data',
				'Accept': 'application/pdf;application/octet-stream'
			}
			const data = { ID: userID, userName: this.user.name };
			const options = { headers: headers };
			this.$http.post(url, data, options).then((res) => {
				//console.log(res)
				document.cookie = `user=${res.body.data[0]}`;
				this.editingName = false;
				this.savingName = false;
			});
		},

		updateEmail: function() {
			this.$refs.profileUserEmail.querySelectorAll('input, button').forEach(e => {
				e.setAttribute('readonly', true)
			})

			this.savingEmail = true;
			const url = this.$apiUrl + '/profile/updateEmail';
			let userID = JSON.parse(atob(this.$getCookieValue('user'))).id;
			this.$http.post(url, { ID: userID, userEmail: this.user.email }).then((res) => {
				this.editingEmail = false;
				this.savingEmail = false;
				if (res.body.succeeded){
					document.cookie = `user=${res.body.data[0]}`;
					this.oldEmail = this.user.email;
				} else {
					NotificationBus.$emit('eventFlashMessage',{
						id: 0,
						title: 'Error',
						message: res.body.message,
						type: 'danger',
						autoClose: false,
						clearList: true
					});
					this.user.email = this.oldEmail;
				}
			});
		},
		/**
		 * Decide si un elemeneto debe estar desabilitado
		 *
		 * @return {object}
		 */
		disabler: function(){
			return {
				'disabled': false
			}
		},

		/**
		 * Devuelve clases para activar el "spìnner" en un elemento
		 *
		 * @param  {boolean} flag
		 * @return {string}
		 */
		spin: function(flag){
			if(flag) return 'fa-spinner fa-spin'
			else return ""
		},

		onEnter: function(event, functionName){
			if (event.keyCode === 13)
				this.$executeFunctionByName(this, functionName);
		}
	}
}