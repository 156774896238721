<template>
	<div ref="downloader">
		<a id="downloader" target="_blank" class="hidden"></a>
	</div>
</template>

<script>
export default{

};
</script>