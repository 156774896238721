import { render, staticRenderFns } from "./email-verification.vue?vue&type=template&id=b5a8a16e&"
import script from "./email-verification.js?vue&type=script&lang=js&"
export * from "./email-verification.js?vue&type=script&lang=js&"
import style0 from "./email-verification.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports