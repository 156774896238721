import invoicelist from '../invoiceList/invoiceList.vue';
// import NotificationBus from '../../../notification-bus.js';
import Contact from "@/models/Contact";

export default{
	mounted(){
		this.searchResult = this.invoices
	},
	data(){
		return {
			searchTerm: '',
			searchResult: []
		}
	},
	components: {
		'invoicelist': invoicelist
	},
	props:['invoices'],
	methods: {
		search: function(/*event*/){
			let term = isNaN(this.searchTerm) ? this.searchTerm.toLowerCase() : this.searchTerm;
			// let data = this.invoices;

			this.searchResult = this.invoices.filter(function(i){
				let customer = new Contact();
				customer.parseJson(i.customer_json);

                if(customer.name != null || customer.name !== undefined) {
                    if (customer.name.toLowerCase().indexOf(term) !== -1 || customer.phone.indexOf(term) !== -1
                        || i.created_at.toLowerCase().indexOf(term) !== -1 || i.total == term) {
                        return customer.name.toLowerCase().indexOf(term) !== -1 || customer.phone.indexOf(term) !== -1
                            || i.created_at.toLowerCase().indexOf(term) !== -1 || i.total == term;
                    }
                }
			});
		}
	}
}