export default class User
{
	constructor(name, email, password, default_invoice_logo_path)
	{
		this.name = name;
		this.email = email;
		this.password = password;
		this.default_invoice_logo_path = default_invoice_logo_path;
	}
}

