export default {
    props: ['row', 'subTotal'],
    data() {
        return {
            old:{
                productPrice: 0.00,
                productQuantity: 0,
                productTotal: 0
            },
            new: {
                productTotal: 0.00,
                productQuantity: 0,
                productPrice: 0.00,
                subTotalValue: 0.00
            },
            priceTmp: 0.00,
            rowID: this.row.id
        }
    },
    mounted() {
        this.sendNewValues();
        this.setNewQuantity();
        // console.log(this.rowID)
    },
    methods: {
        /**
         * Assigns the row price from the props into a modifiable variable
         */
        setRowPrice: function(){
            if (this.row.price === null) this.row.price = '';
            this.priceTmp = (this.row.price === '') ? 0 : this.row.price;
        },
        /**
         * Get row's old values
         */
        getOldValues: function () {
            this.setRowPrice();
            this.old.productQuantity = parseInt(this.row.quantity);
            this.old.productPrice = parseFloat(this.priceTmp);
            this.old.productTotal = parseFloat((this.old.productPrice * this.old.productQuantity).toString());
        },
        /**
         * Emits the new value to the event listener
         */
        sendNewValues: function () {
            this.setRowPrice();
            this.new.productQuantity = parseInt(this.row.quantity);
            this.new.productPrice = parseFloat(this.priceTmp);
            this.new.productTotal = parseFloat((this.new.productPrice * this.new.productQuantity).toString());
            this.new.subTotalValue = parseFloat(((this.subTotal - this.old.productTotal) + this.new.productTotal).toString());
            this.getOldQuantity();
            this.row.total = this.new.productTotal;
            if (!isNaN(this.new.subTotalValue))
                this.$emit('eventChangeSubTotal', this.new.subTotalValue);
        },
        /**
         * Gets the row's old quantity
         */
        getOldQuantity: function () {
            this.old.productQuantity = parseInt(this.row.quantity);
            this.getOldValues();
        },
        /**
         * Sets the new product quantity and updates the total
         */
        setNewQuantity: function () {
            this.new.productQuantity = parseInt(this.row.quantity);
            this.new.productTotal /= this.old.productQuantity;
            this.new.productTotal *= this.new.productQuantity;
            this.sendNewValues();
        },
        /**
         * Removes product row and updates the total
         */
        removeRow: function () {
            if (this.row.price === null) this.row.price = '';
            this.priceTmp = (this.row.price === '') ? 0 : this.row.price;
            this.new.subTotalValue = this.subTotal - (this.priceTmp * this.row.quantity);
            let data = {
                productId: this.row.id,
                newSubTotal: parseFloat(this.new.subTotalValue)
            };
            this.$emit("eventClickRemoveProductRow", data);
        }
    }
}