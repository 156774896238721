/* eslint-disable */
import NotificationBus from '@/notification-bus.js';

export default  {
	mounted(){
		// console.log(this.flashMessage);
		this.messages = this.flashMessage.message.split('\\n');
	},
	data(){
		return{
			newFlashId: 'flash-' + this.flashMessage.id,
			messages: [],
		}
	},
	props: ['flashMessage', 'animation'],
	methods: {
		/**
		 * Emite un evento cuando cierra el mensaje, enviando el id 
		 * del mensaje como contenido
		 */
		emitCloseEvent: function(){
			NotificationBus.$emit('flashMessageClosed', this.flashMessage.id);
		}
	},
	computed: {
		/**
		 * Elige el color de el mensaje basado en el tipo
		 * 
		 * @return {string} El nombre de la clase que da el color
		 */
		setClass: function(){
			return {
				'flash-success color-primary-2': (this.flashMessage.type === 'success'),
				'flash-danger': (this.flashMessage.type === 'danger'),
				'flash-warning': (this.flashMessage.type === 'warning'),
				'flash-info': (this.flashMessage.type === 'info'),
				'hidden': (this.flashMessage.type === undefined || this.flashMessage.type.length <= 0),
				// 'alert-dismissible fade out': (this.animation == 'fade')
			}
		},

		/**
		 * Elige que tan lejos del techo deben estar los mensajes basado
		 * en la posicion de la barra de scroll.
		 * 
		 * @return {string} El nombre de la clase que da el estilo
		 */
		setFlashContainerPosition: function() {
			return {
				'flash-align-top': window.scrollY > 0,
				'flash-align-nav': window.scrollY === 0,
			}
		}
	}
}