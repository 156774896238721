export default class Product
{
	constructor (id = 0, name = '', price = null, quantity = 1, total = 0.00)
	{
		this.id = id;
		this.name = name;
		this.price = price;
		this.quantity = quantity;
		this.total = total;
	}
}