import es from './es';
import en from './en';

const messages = {
	es: es,
	en: en
};

const langs = {
    locale: 'es',
    messages
};

export default langs;