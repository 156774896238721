// import EventBus from '@/event-bus.js';
import NotificationBus from '@/notification-bus.js';

export default{
	mounted(){
		setTimeout(function() {
			// const d = new Date();
			// $("#user_pic_dialog").attr('src', $("#profile-view-image").attr('src')+"?"+d.getTime());
		}, 500);
	},
	data(){
		return{
			newPath: '',
			picture: {
				width: 0,
				height: 0
			},
			files: new FormData(),
		}
	},
	props: ['oldPath'],
	methods: {
		onFileSelected: function(event){
			let divPreview = document.getElementById("uploadedImagePreview");
			// let divInformation = document.getElementById("imageInformation");
			this.files.append("profilePicture", event.target.files[0]);

			let ul = document.getElementById("imageTitleList");
			let fileReader = new FileReader();
			fileReader.readAsDataURL(event.target.files[0]);
			//file = event.target.files[0];
			fileReader.onload = (function(file, picture){
				return function(event){
					if(ul.hasChildNodes()){
						ul.removeChild(ul.childNodes[0]);
					}
					if(divPreview.hasChildNodes()){
						divPreview.removeChild(divPreview.childNodes[0]);
					}

					let li = document.createElement("li");
					li.className = "list-group-item";
					ul.appendChild(li);

					let uploadedImage = new Image();
					uploadedImage.src = event.target.result;
					uploadedImage.onload = function(){
						const fileSize = Math.round(file.size/1000);
						const ar = uploadedImage.width/uploadedImage.height;
						if(ar < 1){
							uploadedImage.height = 250;
							uploadedImage.width = 250 * ar;
						}
						else if(ar === 1){
							uploadedImage.height = 200;
							uploadedImage.width = 200;
						}
						else{
							uploadedImage.height = 200;
							uploadedImage.width = 200 * ar;	
						}
						picture.width = uploadedImage.width;
						picture.height = uploadedImage.height;

						li.innerHTML = "Size: " + fileSize + " kB";
						if(fileSize > 20000){
							li.setAttribute("style", "border: 1px solid red");
							const mssg = document.createElement("p");
							li.appendChild(mssg);
							mssg.innerHTML = "The size is too big";
						}	
					}
					divPreview.appendChild(uploadedImage);
				};
			})(event.target.files[0], this.picture);
		},
		getPicture: function(){
			// const d = new Date();
			// $("#user_pic_dialog").attr('src', $("#profile-view-image").attr('src')+"?"+d.getTime());
		},
		changePicture: function(){
			this.$http.post('/profile/profilePictureChange', this.files, {
				headers: {
					// 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					'Content-Type': 'application/json;multipart/form-data',
					'Accept': 'application/pdf;application/octet-stream'
				}
			}).then(() => {
                NotificationBus.$emit('eventFlashMessage',{
                    id: 0,
                    message: 'Successfully changed picture.',
                    type: 'alert'
                });
                // const d = new Date();

                // $("#profile-view-image").attr('src', response.body.newImg+"?"+d.getTime());
                this.getPicture();
            }, () => {
                NotificationBus.$emit('eventFlashMessage',{
                    id: 0,
                    message: 'There was a problem while changing the picture.',
                    type: 'alert'
                });
            });
			this.picture.width = this.picture.width > 200 ? 200 : this.picture.width;
			this.picture.height = this.picture.height > 200 ? 200 : this.picture.height;
			this.$emit('eventChangePictureSize', this.picture);

			// $("#imageTitleList").empty();
			// $('#modal-profile-view-picture').modal('hide');
		}
	},
}