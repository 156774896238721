/* eslint-disable */
<style>
	.modal-dialog{
		position: absolute;
	}
	h3{
		margin: 10px 0;
	}
</style>
<template>
	<b-modal id="feedbackmodal">
		<!--<template slot="modal-header" slot-scope="{ close }" style="text-align:left;padding:15px;">-->
		<template slot="modal-header" slot-scope="{ close }">
			<h3>Feedback</h3>
			<button type="button" class="close" @click="close()">&times;</button>
		</template>
		<template slot="default">
			<div class="row">
				<div class="col-md-12 col-sm-12 col-xs-12">
					<h3>Your opinion matters.</h3>
					<p>What do you think about this site?</p>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12 col-sm-12 col-xs-12">
					<p>Max {{ updateAvailableCharacters }} characters</p>
				</div>
				<div class="col-md-12 col-sm-12 col-xs-12">
					<textarea name="feedback" cols="50" rows="5" maxlength="500" v-model="comment">
					</textarea>
				</div>
			</div>
		</template>
		<!--	<template slot="modal-footer" style="text-align:center;">	-->
		<template slot="modal-footer">
			<button name="saveComment" class="btn btn-default color-primary-1 email-input-submit pull-right" v-on:click="save"
              :disabled="isSaving">
        Submit
        <i :class="{'fa fa-spinner fa-spin': isSaving}" ></i>
			</button>
		</template>
	</b-modal>
<!--
	
-->
</template>

<script>
	import NotificationBus from '../../notification-bus.js';
	export default {
		mounted(){
			this.$setAuthCode();
		},
		data(){
			return{
				// authCode: this.$getCookieValue('authCode'),
				comment: '',
				charactersAvailable: 0,
				isSaving: false
			}
		},
		methods: {
			save: function(){
				this.isSaving = true;
				let url = this.$apiUrl + '/feedback/save';
				let data = {comment: this.comment};
				let headers = {
					headers:{
						'Authorization': 'Bearer ' + this.$authCode,
						'Content-Type': 'application/json;multipart/form-data'
					}
				};

				this.$http.post(url, data, headers).then(
					succeded => {
						NotificationBus.$emit('eventFlashMessage', {
							id: 0,
							title: 'Success', 
							message: succeded.body.message,
							type: 'success',
							autoClose: false
						});	
					}, 
					failed => {
						//console.log(failed)
						NotificationBus.$emit('eventFlashMessage', {
							id: 0,
							message: failed.body.message,
							type: 'danger',
							autoClose: false
						});
					}
				).finally(() => {
					this.isSaving = false;
				});
				this.comment = '';
				// $(this.$el).modal('hide');
			}
		},
		computed:{
			updateAvailableCharacters: function(){
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				return (this.charactersAvailable = 500 - this.comment.length);
			}
		}
	};
</script>