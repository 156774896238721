// noinspection DuplicatedCode
'use strict'
import invoiceactions from './subcomponents/invoiceActions/invoiceActions.vue';
import chooseTemplate from '@/components/modals/chooseTemplate.vue';
import taxrow from './subcomponents/taxRow/taxRow.vue';
import productrow from './subcomponents/productRow/productRow.vue';
import taskrow from './subcomponents/taskRow/taskRow.vue';
import Invoice from '@/models/Invoice.js';
import Tax from '@/models/Tax.js';
import Product from '@/models/Product.js';
import Task from '@/models/Task.js';
import GlobalEventBus from '@/event-bus.js';
import NotificationBus from '@/notification-bus';
import Notification from '@/models/Notification';

export default {
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.isGuest = vm.$authCode === null;

            if ((vm.updateTemplate || vm.newTemplate) && (vm.$authCode === undefined || vm.$authCode == null)) {
                next('/login-view');
            }
        });
    },
    // beforeRouteUpdate(to, from, next){
    //
    // },
    mounted() {
        this.$setAuthCode();
        this.updateTemplate = this.updateTemplateProp || false;
        this.newTemplate = this.newTemplateProp || false;
        if (this.updateTemplate && this.$authCode != null) {
            this.getTemplateInfo(this.$route.params.id);
        }
        this.setCurrentDate();
        this.isGuest = this.$getCookieValue('authCode') == null;
        GlobalEventBus.$on(this.$constants.eventNames.loggedOut, () => {
            this.isGuest = true;
        });
        this.leftAdsenseContent = document.getElementById('lefaddisplaynone').innerHTML
        this.rightAdsenseContent = document.getElementById('rightaddisplaynone').innerHTML
        this.bottomAdsenseContent = document.getElementById('bottomaddisplaynone').innerHTML

    },
    components: {
        invoiceactions,
        taxrow,
        productrow,
        taskrow,
        chooseTemplate,
    },
    data() {
        return {
            leftAdsenseContent: '',
            rightAdsenseContent: '',
            bottomAdsenseContent: '',
            invoiceTypeEnum: Object.freeze({"PRODUCTS": 0, "TASKS": 1}),
            invoice: new Invoice(),
            templates: [],
            productRowQuantity: 1,
            tasksRowQuantity: 1,
            taxRowQuantity: 1,
            isGuest: true,
            inFavorOf: JSON.parse("{}"),
            sawBetaVersion: false,
            showTime: false,
            globalRateFlag: false,
            globalRate: 0.00,
            hasLogo: false,
            showPlaceHolder: true,
            updateTemplate: false,
            newTemplate: false,
            errors: {
                invoice: {},
                hasErrors: false
            },
            logo: {
                width: 0,
                height: 0
            },
            typeValues: {
                product: {
                    subTotal: 0.00,
                    products: []
                },
                task: {
                    subTotal: 0.00,
                    tasks: []
                }
            }
        }
    },
    props: ['isTemplate', 'updateTemplateProp', 'newTemplateProp'],
    methods: {
        /**
         * Sets the bill date to the current date
         */
        setCurrentDate: function () {
            let today = new Date();
            let timezone = today.getTimezoneOffset() / 60;
            today.setHours(today.getHours() - timezone)
            this.invoice.billdate = today.toJSON().slice(0, 10);
            this.invoice.billtime = today.getUTCHours()
                + ':' + today.getUTCMinutes()
                + ':' + today.getUTCSeconds();
        },
        /**
         * Adds row to the taxes list
         */
        newTaxRow: function () {
            let i;
            for (i = 0; i < this.taxRowQuantity; i++) {
                this.invoice.taxes.push(new Tax(this.invoice.taxes.length));
            }
        },
        /**
         * Adds row to the products list
         */
        newProductRow: function () {
            let i;
            for (i = 0; i < this.productRowQuantity; i++) {
                this.invoice.products.push(new Product(this.invoice.products.length));
            }
            //console.log(this.invoice)
        },
        /**
         * Adds row to the tasks list
         */
        newTaskRow: function () {
            let i;
            for (i = 0; i < this.tasksRowQuantity; i++) {
                let rateTmp = (this.globalRateFlag) ? this.globalRate : 0.00;
                this.invoice.tasks.push(new Task(this.invoice.tasks.length, rateTmp));
            }
        },
        /**
         * Removes tax row from list
         * @return void
         * @param data
         */
        removeTaxRow: function (data) {
            this.invoice.taxTotal = data.newTaxTotal;
            this.invoice.taxes.splice(data.taxId, 1);
            for (let i = 0; i < this.invoice.taxes.length; i++) {
                this.invoice.taxes[i].id -= (this.invoice.taxes[i].id > data.taxId) ? 1 : 0;
            }
            this.updateTotal();
        },
        /**
         * Removes product row
         * @return void
         * @param data
         */
        removeProductRow: function (data) {
            this.invoice.subTotal = data.newSubTotal;
            this.invoice.products.splice(data.productId, 1);
            let i;
            for (i = 0; i < this.invoice.products.length; i++) {
                this.invoice.products[i].id -= (this.invoice.products[i].id > data.productId) ? 1 : 0;
            }
            this.updateTotal();
        },
        /**
         * Removes task row
         * @return void
         * @param data
         */
        removeTaskRow: function (data) {
            this.invoice.subTotal = data.newSubTotal;
            this.invoice.tasks.splice(data.taskId, 1);
            let i;
            for (i = 0; i < this.invoice.tasks.length; i++) {
                this.invoice.tasks[i].id -= (this.invoice.tasks[i].id > data.taskId) ? 1 : 0;
            }
            this.updateTotal();
        },
        /**
         * Updates the value of the tax total with the new value of the row
         * @param  {Tax} data
         * @return {[type]}
         */
        changeTaxTotal: function (data) {
            this.invoice.taxes[data.id].amount = data.amount
            this.invoice.taxTotal = isNaN(data.newTaxTotal) ? 0 : (data.newTaxTotal < 0 ? 0 : data.newTaxTotal);

            this.updateTotal();
        },
        /**
         * [changeSubTotal description]
         * @param  {[type]} newSubTotal
         * @return {[type]}
         */
        changeSubTotal: function (newSubTotal) {
            this.invoice.subTotal = parseFloat(newSubTotal.toString());
            this.updateTotal();
        },
        /**
         * [updateTotal description]
         * @return {[type]}
         */
        updateTotal: function () {
            this.invoice.total = parseFloat((this.invoice.subTotal * (1 + (this.invoice.taxTotal / 100))).toString());
            this.invoice.total = isNaN(this.invoice.total) ? 0 : this.invoice.total;
            this.invoice.total = this.invoice.total < 0 ? 0 : this.invoice.total;
        },
        /**
         * [chooseLogo description]
         * @return {[type]}
         */
        chooseLogo: function () {
            this.$refs.logoChooser.click();
        },
        /**
         *
         * @param image
         * @param logoContainerStyles
         */
        setOrientationBasedOnAspectRatio: function (image, logoContainerStyles) {
            let ar = image.width / image.height;

            if (ar >= 1) {
                image.width = logoContainerStyles['max-height'].replace('px', '');
                image.height = image.width / ar;
            } else if (ar < 1) {
                image.height = logoContainerStyles['max-height'].replace('px', '');
                image.width = image.height * ar;
            }
        },
        /**
         * [addLogo description]
         * @param {[type]} event
         */
        addLogo: function (event) {
            let fileReader = new FileReader();
            let file = event.target.files[0];

            fileReader.readAsDataURL(file);
            fileReader.onload = (function (file, self) {
                return function (event) {
                    self.hasLogo = true;
                    self.showPlaceHolder = false;
                    let logoContainerStyles = getComputedStyle(self.$refs.logoContainer, null);
                    let i = new Image();
                    i.onload = function () {
                        (function (image, logoStyle) {
                            return self.setOrientationBasedOnAspectRatio(image, logoStyle);
                        })(i, logoContainerStyles);
                        self.logo.width = i.width;
                        self.logo.height = i.height;
                    }
                    i.src = event.target.result;
                    i.remove();

                    self.invoice.template_logo = event.target.result;
                    self.$refs.logoPreview.setAttribute('class', '');
                };
            })(file, this);
        },
        /**
         * Removes the uploaded logo
         */
        removeLogo: function () {
            this.hasLogo = false;
            this.showPlaceHolder = true;
            this.invoice.template_logo = null;
        },
        /**
         * [setInFavorOf description]
         */
        setInFavorOf: function () {
            this.invoice.bussiness.name = this.inFavorOf.user.name;
            this.invoice.bussiness.email = this.inFavorOf.user.email;
            this.invoice.bussiness.phone = this.inFavorOf.user.phone;
            this.invoice.bussiness.address = this.inFavorOf.user.address;
        },
        /**
         * [clearAll description]
         * @param  {string} choice
         * @return {[type]}
         */
        clearAll: function (choice) {
            switch (choice) {
                case 'p':
                    this.invoice.products = [];
                    this.invoice.subTotal = 0;
                    this.invoice.total = 0;
                    break;
                case 't':
                    this.invoice.total = this.invoice.subTotal
                    this.invoice.taxes = [];
                    this.invoice.taxTotal = 0;
                    break;
                case 'k':
                    this.invoice.tasks = [];
                    this.invoice.subTotal = 0;
                    this.invoice.total = 0;
                    break;
                default:
                    break;
            }
        },
        /**
         * [showBillingTime description]
         * @return {[type]}
         */
        showBillingTime: function () {
            this.showTime = !this.showTime;
        },
        /**
         * [toggleGlobalRateFlag description]
         * @param  {[type]} event
         * @return {[type]}
         */
        toggleGlobalRateFlag: function (event) {
            if (event !== undefined) this.globalRateFlag = !this.globalRateFlag;
        },
        /**
         * [setGlobalRate description]
         */
        setGlobalRate: function () {
            let newSubTotal = 0;
            this.invoice.tasks.forEach(task => {
                if (task.rate !== this.globalRate) {
                    task.rate = this.globalRate;
                    let newTaskTotal = (parseFloat((this.globalRate * task.value).toString()));
                    task.total = newTaskTotal;
                    newSubTotal += newTaskTotal;
                }
            })
            this.invoice.subTotal = parseFloat(newSubTotal.toFixed(2));
        },
        /**
         * [changeInvoiceType description]
         * @param  {[type]} type
         * @return {[type]}
         */
        changeInvoiceType: function (type) {
            this.invoice.type = type;
            this.changeTotal();
        },
        /**
         * [changeTotal description]
         * @return {[type]}
         */
        changeTotal: function () {
            if (this.invoice.type === this.invoiceTypeEnum.PRODUCTS) {
                this.typeValues.task.subTotal = this.invoice.subTotal;
                this.typeValues.task.tasks = this.invoice.tasks;

                this.invoice.subTotal = this.typeValues.product.subTotal;
                this.invoice.products = this.typeValues.product.products;
            } else if (this.invoice.type === this.invoiceTypeEnum.TASKS) {
                this.typeValues.product.subTotal = this.invoice.subTotal;
                this.typeValues.product.products = this.invoice.products;

                this.invoice.subTotal = this.typeValues.task.subTotal;
                this.invoice.tasks = this.typeValues.task.tasks;
            }
            this.updateTotal();
        },
        /**
         * [setInvoiceId description]
         * @param {[type]} invoiceId
         */
        setInvoiceId: function (invoiceId) {
            this.invoice.id = invoiceId;
        },
        /**
         * [selectedTemplate description]
         * @return {[type]}
         */
        getTemplateInfo: function (templateId) {
            let notification = new Notification();
            let uri = this.$apiUrl + '/template/get/' + templateId;
            this.$http.get(uri, {
                headers: {
                    'Authorization': 'Bearer ' + this.$authCode,
                    'Content-Type': 'application/json;multipart/form-data',
                    'Accept': 'application/pdf;application/octet-stream'
                }
            }).then((response) => {
                    this.invoice.templateName = response.body.data.templateName;
                    if (this.invoice.isTemplate) this.invoice.id = response.body.data.id;
                    this.invoice.number = response.body.data.invoice_number;

                    this.invoice.bussiness = JSON.parse(response.body.data.bussiness_json);
                    this.invoice.customer = JSON.parse(response.body.data.customer_json);

                    let tasks = JSON.parse(response.body.data.tasks);
                    if (tasks.length > 0) {
                        this.changeInvoiceType(this.invoiceTypeEnum.TASKS)
                        this.invoice.tasks = tasks;
                    } else {
                        this.invoice.type = this.invoiceTypeEnum.PRODUCTS;
                        this.invoice.products = JSON.parse(response.body.data.products);
                        if (this.invoice.products.length > 0)
                            this.changeSubTotal(this.invoice.products.map(p => p.total).reduce((total, num) => {
                                return total + num;
                            }))
                        else
                            this.changeSubTotal(0);
                    }

                    this.invoice.taxes = JSON.parse(response.body.data.taxes);

                    for (let i = 0; i < this.invoice.taxes.length; i++) {
                        this.invoice.taxes[i].amount = (this.invoice.subTotal * (this.invoice.taxes[i].percent / 100.00))
                        this.changeTaxTotal(this.invoice.taxes[i]);
                    }
                    if (this.invoice.taxes.length > 0)
                        this.invoice.taxTotal = this.invoice.taxes.map(tax => tax.percent).reduce((total, num) => {
                            return (parseFloat(total) + parseFloat(num)).toFixed(2);
                        })
                    else
                        this.invoice.taxTotal = 0.00;

                    this.updateTotal();
                    this.invoice.comments = response.body.data.comments;
                    this.invoice.template_logo = response.body.data.template_logo;
                    let logoContainerStyles = getComputedStyle(this.$refs.logoContainer, null);

                    if (this.invoice.template_logo != null) {
                        let i = new Image();
                        i.onload = function () {
                            (function (image, logoStyle) {
                                return self.setOrientationBasedOnAspectRatio(image, logoStyle);
                            })(i, logoContainerStyles);
                            this.logo.width = i.width;
                            this.logo.height = i.height;
                        }
                        this.hasLogo = true;
                        this.invoice.template_logo = event.target.result;
                        i.src = this.invoice.template_logo;
                    }
                },
                failed => {
                    notification.id = 0;
                    notification.type = 'danger';
                    notification.title = 'COMMON.ERROR';
                    notification.message = 'TEMPLATE.NOTFOUND' + failed.data();
                    NotificationBus.emitNotification(notification);
                    //console.log(failed);
                })
            // .catch(error => {
            // 	//console.log(error);
            // })
        },
        markErrors: function (errors) {
            this.errors = errors;
        },
        getErrorClass: function (inputError, classes = '') {
            return classes += this.$isNullOrUndefined(inputError) ? '' : (inputError.hasError ? ' inputError' : '');
        },
        getErrorMessage: function (inputError) {
            return this.$isNullOrUndefined(inputError) ? '' : (inputError.hasError ? inputError.message : '');
        }
    },
    computed: {
        checkBoxToggler: function () {
            return {
                'fa-square-o': !this.invoice.showTime,
                'fa-check-square-o': this.invoice.showTime
            }
        },
        logoContainerClasses: function () {
            return {
                'no-border-box-shadow visible-block': this.hasLogo
            }
        },
        customSliderClass: function () {
            return {
                'custom-slider-button-on': this.globalRateFlag,
                'custom-slider-button-off': !this.globalRateFlag
            }
        }
    }
}