export default {
    mounted(){},
    data() {
        return {
            price: 5.00,
            cardType: 'default',
            cardNumber: '',
            securityCode: '',
            expDate: '',
            name : ''
        }
    },
    props: [],
    computed: {
        cardBrand: function(){
            return {
                'fa-cc-amex' : this.cardType === 'amex',
                'fa-cc-visa' : this.cardType === 'visa',
                'fa-cc-mastercard' : this.cardType === 'mastercard',
                'fa-cc-discover' : this.cardType === 'discover',
                'fa-credit-card' : this.cardType === 'default',
            }
        }
    },
    methods:{
        replaceIfNaN: function(event, value, isDate){
            if(event.keyCode !== 8 && !(event.keyCode >= 96 && event.keyCode <= 105)){
                if(!isDate){
                    value = value.replace(/[^0-9]/g, '');
                }
                else{

                    value = value.replace(/[^0-9/]/g, '');
                }
            }
            return value;
        },
        validateCardNumber: function(event){
            this.cardNumber = this.replaceIfNaN(event, this.cardNumber, false);

            const first = parseInt(this.cardNumber.substr(0,1));
            const first2 = parseInt(this.cardNumber.substr(0,2));
            const first3 = parseInt(this.cardNumber.substr(0,3));
            const first4 = parseInt(this.cardNumber.substr(0,4));
            const first6 = parseInt(this.cardNumber.substr(0,6));

            if(first === 4){
                this.cardType = 'visa';
            }
            else if(first2 >= 34 && first2 <= 37)
            {
                this.cardType = 'amex';
            }
            else if((first2 >= 51 && first2 <= 55) || (first2 >= 22 && first2 <= 27))
            {
                this.cardType = 'mastercard';
            }
            else if (first4 === 6011 || first2 === 65
                || (first6 >= 622126 && first6 <= 622925)
                || (first3 >= 644 && first3 <= 649))
            {
                this.cardType = 'discover';
            }
            else
            {
                this.cardType = 'default';
            }
            this.cardNumber.$emit;
        },
        validateExpiration: function(event){
            this.expDate = this.replaceIfNaN(event, this.expDate, true);
            if(this.expDate.length === 2 && event.keyCode !== 8){
                this.expDate += '/';
            }
            this.expDate.$emit;
        },
        validateSecurityCode: function(event){
            this.securityCode = this.replaceIfNaN(event, this.securityCode, false);
            this.securityCode.$emit;
        },
        cycleOnChange: function(){
            //console.log(this.cycle);
            if(this.cycle === 'm'){
                this.cyclePrice = 5.00;
            }
            else if(this.cycle === 'y'){
                this.cyclePrice = 50.00;
            }
            else if(this.cycle === 't'){
                this.cyclePrice = 0.00;
            }
        },
        eventEmitNumber: function(){
            this.$emit('setCardNumber', this.cardNumber);
        },
        eventEmitSecurityCode: function(){
            this.$emit('setSecurityCode', this.securityCode);
        },
        eventEmitName: function(){
            this.$emit('setName', this.name);
        },
        eventEmitExpirationDate: function(){
            this.$emit('setExpirationDate', this.expDate);
        }
    }
}