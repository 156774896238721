<template>
	<div class="row">
		<div class="col-md-12 col-sm-12 col-xs-12 ">
			<flashmessage v-for="flash in flashes"
							:key=JSON.stringify(flash)
							:flashMessage="flash">
			</flashmessage>
		</div>
	</div>
</template>
<script>
	import flash from './flash-component.vue';
	// import EventBus from '../../event-bus.js';
	import NotificationBus from '@/notification-bus.js'

	export default{
		mounted(){
			
			// $('#vue-flash').css('left', (100 * (425 / ($('body').innerWidth() - 425) )) + '%');
			// console.log($('body').innerWidth() - 425) 
		},
		components: { 
			'flashmessage': flash
		},
		mixins: [
			'flashVue'
		],
		data(){
			return {
				flashes: NotificationBus.flashes,
				alertAnimation: "fade"
			}
		},
		methods: {}
	};
</script>