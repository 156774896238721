<template>
	<div class="row">
		<div class="col-10 my-0 mx-auto"><input type="text"></div>
		<div class="col-10 my-0 mx-auto">
			<div class="accordion" role="tablist">
				<b-card v-for="q in questions" :key="q.id" no-body class="mb-1">
					<b-card-header header-tag="header" class="p-1" role="tab">
						<b-button block v-b-toggle="'accordion-'+q.id" variant="info">
							{{ q.question }}
						</b-button>
					</b-card-header>
					<b-collapse :id='"accordion-"+q.id'  role="tabpanel">
						<b-card-body>
							<b-card-text> {{ q.answer }} </b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: "faq-view",
	data(){
		return {
			questions: [
				{ id: 0, question: "Is this a question?", answer: "It is!" },
				{ id: 1, question: "Is this a question?", answer: "It is!" },
			]
		}
	}
}
</script>

<style scoped>

</style>