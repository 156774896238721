import Vue from 'vue';
import App from '@/App.vue';
import Globals from '@/plugins/globals';
import router from '@/router';
import Resource from 'vue-resource';
import VueI18n from 'vue-i18n';
import langs from '@/i18n/index';
import BootstrapVue from 'bootstrap-vue';
import PortalVue from 'portal-vue';
import vueJquery from 'vue-jquery';
import 'vue-jquery/dist/vueJquery.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import moment from 'moment';

Vue.prototype.moment = moment

Vue.config.productionTip = false;
Vue.use(Globals);
Vue.use(Resource);
Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(PortalVue);
Vue.use(vueJquery);

const i18n = new VueI18n(langs);

new Vue({
    router: router,
    i18n: i18n,
    render: h => h(App)
}).$mount('#app');

// Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';