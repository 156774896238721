export default {
    props: ['row','taxTotal', 'subTotal'],
    data(){
        return{
            oldVal: 0.00,
            newVal: 0.00,
            newTaxTotal: 0.00,
            taxTmp: 0.00,
            amount: 0.00
        }
    },
    mounted() {
        // console.log(this.row)
        this.amount = this.row.amount;
    },
    watch:{
        subTotal(){
            this.amount = (this.row.percent/100) * this.subTotal;
        }
    },
    methods:{
        saveOldValue: function(){
            if (this.row.percent === null) this.row.percent = '';
            this.taxTmp = (this.row.percent === '') ? this.taxTmp : this.row.percent;
            this.oldVal = parseFloat(this.taxTmp);
        },
        changeValue: function(event){
            if(event.key === 'ArrowUp' || event.key === 'ArrowDown'){
                this.saveOldValue();
            }
            this.calculateValue();
        },
        calculateValue: function(){
            if (this.row.percent === null) this.row.percent = '';
            this.taxTmp = (this.row.percent === '') ? this.taxTmp : this.row.percent;
            this.newVal = 0;
            this.newTaxTotal = 0;

            this.newVal = parseFloat(this.taxTmp);

            this.newTaxTotal = parseFloat(parseFloat(this.taxTotal) - this.oldVal);
            this.newTaxTotal += this.newVal;
            //console.log(this.taxTmp, this.newVal, this.newTaxTotal, this.oldVal)
            this.saveOldValue();
            this.amount = parseFloat(this.subTotal * (this.taxTmp/100)).toFixed(2);

            let data = {
                id: this.row.id,
                newTaxTotal: this.newTaxTotal,
                amount: this.amount
            };
            this.$emit('eventChangeTaxTotal', data);
        },
        removeRow(){
            if (this.row.percent === null) this.row.percent = '';
            this.taxTmp = (this.row.percent === '') ? this.taxTmp : this.row.percent;
            this.newTaxTotal = this.taxTotal - this.taxTmp;
            let data = {
                taxId: this.row.id,
                newTaxTotal: parseFloat(this.newTaxTotal)
            };
            this.$emit("eventClickRemoveTaxRow", data);
        }
    },
    computed: {
        getTaxAmount: function(){
            return parseFloat(this.subTotal * (this.row.percent/100)).toFixed(2);
        }
    }
}