const es = {
	common: {
		login: 'Iniciar sesión',
		registration: 'Registración',
		register: 'Registrate',
		edit: 'Editar',
		delete: 'Eliminar',
		selectLogo: '[Seleccione Logo]',
		clearAll: 'Borrar',
		add: 'Añadir',
		name: 'Nombre',
		eMail: 'Correo Electrónico',
		password: 'Contraseña',
		phone: 'Teléfono',
		address: 'Dirección',
		rows: 'Filas',
		number: 'Número',
		cycle: 'Ciclo',
		start: 'Inicio',
		end: 'Final',
		autoRenew: 'Auto Renovación',
		new: 'New',
		buy: 'Comprar',
		pay: 'Pagar',
		save: 'Guardar',
		verifying: 'verificando',
		verified: 'verificado',
		redirecting: 'redireccionando'
	},
	topNavBar: {},
	login: {
		rememberMe: 'Recuérdame',
		forgotPassword: '¿Olvidó su contraseña?'
	},
	registration: {
		confirmPassword: 'Confirmar contraseña',
		cycle: 'Ciclo',
		creditCard: 'Tarjeta de crédito',
		nameOnCard: 'Nombre en la tarjeta',
		cardNumber: 'Número de la tarjeta',
		expirationDate: 'Fecha de expiración', 
		trial: '30 dias de prueba',
		monthly: 'Mensual',
		yearly: 'Anual',
		userExists: 'Ya existe una cuenta con ese correo electrónico.'
	},
	invoice: {
		chooseTemplate: 'Selecciona plantilla',
		receiptInfo: 'Información del recibo',
		receiptNumber: 'Número del recibo',
		billDate: 'Fecha billiado',
		inFavorOf: 'Pagar a',
		billTo: 'Cobrar a',
		products: 'Productos',
		price: 'Precio ($)',
		quantity: 'Cantidad',
		productTotal: 'Total de Productos',
		tasks: 'Tareas',
		taskDescription: 'Descripción de tarea',
		rate: 'Razón ($/hr)',
		hours: 'Horas',
		total: 'Total',
		totals: 'Totales',
		actions:'Acciones',
		clearAll: 'Borrar Todo',
		taxes: 'Taxes',
		percent: 'Porciento (%)',
		amount: 'Cantidad',
		subTotal: 'Sub Total',
		taxesTotal: 'Total de Taxes',
		comments: 'Comemtarios',
		productsTooltip: 'Lista de todos los productos a ser cobrados.',
		tasksTooltip: 'Lista de todas las tareas a ser cobradas.',
		taxesTooltip: 'Lista detodos los taxes a ser incluidos.'
	},
	template: {
		title: 'Plantillas'
	},
	profile: {
		profile: 'Perfil',
		yourInformation: 'Informatión Personal',
		joined: 'Miembro desde',
		history: 'Historial',
		addNewTemplate: 'Añadir nueva plantilla',
		dashboard: 'Panel',
		verifyEmailChange: 'Favor de verificar su correo electrónico para terminar el proceso.',
		yourInvoices: 'Tus Recibos'
	},
	feedback: {
		thanks: 'Gracias por el feedback!'
	},
	errors: {
		invalidCredentials: 'Ha entrado credenciales incorrectas.',
		emptyEmail: 'El campo de correo electrónico es requerido.',
		emptyPassword: 'El campo de contraseña es requerido.',
	}
};

export default es;