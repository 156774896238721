import { render, staticRenderFns } from "./taskRow.vue?vue&type=template&id=2ca1525e&"
import script from "./taskRow.js?vue&type=script&lang=js&"
export * from "./taskRow.js?vue&type=script&lang=js&"
import style0 from "./taskRow.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports