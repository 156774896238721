import GlobalEventBus from '@/event-bus.js';

export default{
	mounted(){
		this.route = JSON.parse(this.routes);
		this.isGuest = this.$getCookieValue('authCode') == null;
		GlobalEventBus.$on(this.$constants.eventNames.loginSuccess,() => {
			this.handleLoginResponse(); 
		});
		if (document.cookie.indexOf('cli-lang') === -1) {
			let expirationDate = new Date(new Date().getFullYear() + 2,0,1)
			this.lang = navigator.languages[1];
			document.cookie = 'cli-lang=' + this.lang + ';expires=' + expirationDate + ';samesite=strict';
		}
	},
	data(){
		return{
			csrf: '',
			isGuest: true,
			route: {}
		}
	},
	props:['userName', 'appName', 'routes'],
	methods:{
		/**
		 * Closes session, setting user as guest and broadcasting to entire application
		 * @param  {MouseEvent} event
		 * @return void
		 */
		logout: function(event){
			event.preventDefault();
			this.isGuest = true;
			this.$deleteAllCookies();
			this.$authCode = null;
			location.reload();
		},
		/**
		 * Handles the broadcast event after a successful login-view
		 * @return void
		 */
		handleLoginResponse: function() {
			this.isGuest = false;
		},
		/**
		 * Changes the application to the selected language
		 * @return void
		 * @param {string} l
		 */
		changeLanguage: function(l){
			this.lang = l;
			this.$i18n.locale = this.lang;
			this.moment.locale(this.lang);
			let expirationDate = new Date(new Date().getFullYear() + 2,0,1)
			document.cookie = 'cli-lang=' + this.lang + ';expires=' + expirationDate + ';samesite=strict';
		}
	}
}