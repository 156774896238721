<style src="./App.css"></style>
<style src="./assets/css/font-awesome-4.7.0/css/font-awesome.min.css"></style>
<template>
	<div id="app">
		<topnavbar user-name="" app-name="YouInvoice" routes='{"login":"/login","register":"/register","logout":"/logout"}'>
		</topnavbar> 

		<div id="vue-flash" class="container">
			<flashContainer></flashContainer>
		</div>

		<transition name="fade" mode="out-in">
			<router-view></router-view>
		</transition>

		<!-- <thisIsBeta id="betaversionnoticemodal" class="modal fade" role="dialog">
		</thisIsBeta> -->

		<feedbackModal></feedbackModal>
    <payment></payment>
<!--    <editUserInfoModal></editUserInfoModal>-->
  </div>
</template>

<script>
	import topnavbar from '@/components/shared/layout/topnavbar-component/topnavbar-component.vue';
	import flashContainer from '@/components/flash/flashContainer.vue';
	import feedbackModal from '@/components/modals/FeedbackModal.vue';
  import payment from '@/components/modals/payment-modal/payment-modal.vue';
  // import editUserInfoModal from '@/components/modals/editProfileInfo/editProfileInfo.vue'

  export default {
		mounted(){
      this.$i18n.locale = this.lang;
		},
		data (){
			return {
				lang: this.$getCookie('cli-lang')
			}
		},
		components: { 
			topnavbar, 
			flashContainer,
			// thisIsBeta,
			feedbackModal,
      payment,
      // editUserInfoModal,
			// chooseTemplate,
		},
		methods: {
			/**
			 * Changes the language of the application
			 * @return void
			 */
			changeLanguage: function(l){
				this.lang = l;
				this.$i18n.locale = this.lang;
			}
		}
	};
</script>  

