<style>
	.template-card-holder{
		text-align: center;
	}

	.template-card{
		display: inline-block;
		margin: 5px;
		height: 200px; 
		width: 140px; 
		box-shadow: 0px 1px 4px #B5B5B5;
		vertical-align: middle;
		border-radius: 15px;
	}

	.template-card img {
		width: 90%;
		height: 140px;
		border-radius: 5px;
		vertical-align: middle;
		box-shadow: 0 0 3px rgba(0,0,0,0.6);
		margin: 0 auto;
		display: block;
	}

	.template-header {
		padding-left: 15px;
		padding-top: 5px;
	} 
	.template-header button { 
		box-shadow: none;
	}
</style>
<template>
	<b-modal size="lg" id="choosetemplatemodal">
		<template slot="modal-header" slot-scope="{ close }">
			<h4> {{ $t('template.title') }} </h4>
			<button type="button" class="close" @click="close()">&times;</button>
		</template>
		<template slot="default">
			<div v-if="loadingTemplates" class="modal-loader">
				<i class="fa fa-spinner fa-spin" style=""></i>
			</div>
			<div v-bind:key="template" v-for="template in templates" class="template-card">
				<div class="template-header">
					<p class="template-title"> {{ template.templateName }} </p>
					<div class="elipsis-option dropdown">
						<b-dropdown size="sm" class="remove-border-shadow" variant="transparent">
							<template slot="button-content"></template>
							<b-dropdown-item v-on:click="deleteTemplate(template.id, index)">
								{{ $t('common.delete') }}
							</b-dropdown-item>
							<b-dropdown-item v-on:click="goToTemplate(template.id)">
								{{ $t('common.edit') }}
							</b-dropdown-item>
						</b-dropdown>
					</div>
				</div>
				<div class="template-body">
					<img :src="template.template_logo">
				</div>
			</div>
		</template>
		<template slot="modal-footer">
			<p></p>
		</template>
	</b-modal>
</template>

<script>
	// import Invoice from '@/models/Invoice';
	import User from '@/models/User';
	export default {
		mounted(){
			this.getTemplates();
			this.user = JSON.parse(this.$getCookieValue('user'));
		}, 
		data(){
			return {
				templates: [],
				user: new User(),
				loadingTemplates: false
			}
		}, 
		methods:{
			/**
			 * Return user's invoice templates
			 * @return Invoice[]
			 */
			getTemplates: function(){
				this.loadingTemplates = true;
				
				const u = this.user;
				if (this.user.id !== undefined) 
				{
					setTimeout(() => {
						let uri = this.$apiUrl + '/template/get/byuserid/' + u.id;
						this.$http.get(uri, {
							headers: {
								'Authorization': 'Bearer ' + this.authCode,
								'Content-Type': 'application/json;multipart/form-data',
								'Accept': 'application/pdf;application/octet-stream'
							}
						}).then(
							success => {
								this.templates = success.body.data;
							}, 
							() => {	}
						).finally(() => { this.loadingTemplates = false; })
					}, 2000)
				}
			},

			/**
			 * Deletes the invoice template
			 * @return ResponseMessage
			 */
			deleteTemplate: function(/*templateId, index*/){

			},

			/**
			 * Fills the invoice with the selected invoice template
			 * @return Invoice
			 */
			goToTemplate: function(/*templateId*/){

			}
		}
	};
</script>