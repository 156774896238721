const en = {
    common: {
		login: 'Login',
		registration: 'Registration',
		register: 'Register',
		edit: 'Edit',
		delete: 'Delete',
		selectLogo: '[Select Logo]',
		clearAll: 'Clear All',
		add: 'Add',
		name: 'Name',
		eMail: 'Email',
		password: 'Password',
		phone: 'Phone',
		address: 'Address',
		rows: 'Rows',
		number: 'Number',
		cycle: 'Cycle',
		start: 'Start',
		end: 'End',
		autoRenew: 'Auto Renew',
		new: 'New',
		pay: 'Pay',
		buy: 'Buy',
		save: 'Save',
		verifying: 'verifying',
		verified: 'verified',
		redirecting: 'redirecting'
	},
	topNavBar: {},
	login: {
		rememberMe: 'Remember me',
		forgotPassword: 'Forgot your password?'
	},
	registration: {
		confirmPassword: 'Confirm password',
		cycle: 'Cycle',
		creditCard: 'Credit Card',
		nameOnCard: 'Name on card',
		cardNumber: 'Card number',
		expirationDate: 'Expiration date', 
		trial: '30 day trial',
		monthly: 'Monthly',
		yearly: 'Yearly',
		userExists: 'An account with that email address already exists.'
	},
	invoice:{
		chooseTemplate: 'Choose template',
		receiptInfo: 'Receipt Info',
		receiptNumber: 'Receipt Number',
		billDate: 'Bill Date',
		inFavorOf: 'In Favor Of',
		billTo: 'Bill To',
		products: 'Products',
		price: 'Price ($)',
		quantity: 'Quantity',
		productTotal: 'Product Total',
		tasks: 'Tasks',
		taskDescription: 'Task Description',
		rate: 'Rate ($/hr)',
		hours: 'Hours',
		total: 'Total',
		totals: 'Totals',
		actions:'Actions',
		clearAll: 'Clear All',
		taxes: 'Taxes',
		percent: 'Percent (%)',
		amount: 'Amount',
		subTotal: 'Sub Total',
		taxesTotal: 'Taxes Total',
		comments: 'Comments',
		productsTooltip: 'List of all the products to be billed.',
		tasksTooltip: 'List of all the tasks to be billed.',
		taxesTooltip: 'List of all the taxes to be included.'
	},
	template: {
		title: 'Templates'
	},
	profile: {
		profile: 'Profile',
		yourInformation: 'Your Information',
		joined: 'Joined',
		history: 'History',
		addNewTemplate: 'Add new template',
		dashboard: 'Dashboard',
		verifyEmailChange: 'Check your email to finish changing the email.',
		yourInvoices: 'Your Invoices'
	},
	feedback: {
		thanks: 'Thanks for the feedback!'
	},
	errors: {
		invalidCredentials: 'Wrong email/password combination.',
		emptyEmail: 'The email is required.',
		emptyPassword: 'The password is required.',
	}
};

export default en;