import { render, staticRenderFns } from "./faq-view.vue?vue&type=template&id=d3337e60&scoped=true&"
import script from "./faq-view.vue?vue&type=script&lang=js&"
export * from "./faq-view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3337e60",
  null
  
)

export default component.exports