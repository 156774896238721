import Vue from 'vue';
import Notification from './models/Notification.js';

let NotificationBus;
export default NotificationBus = new Vue({
	data: {
		flashes: [], //Notification
		sizeLimit: 6
	},
	methods: {
		emitNotification: function(notification = new Notification()) {
			this.$emit('eventFlashMessage', notification)
		},
		// eslint-disable-next-line no-unused-vars
		addFlash: function(response, message = null, autoClose = false, clearList = false){
			if(clearList){
				this.$emit('clearFlashMessages');
			}
		},
		popOnSizeLimit: function(clearList){
			if(this.flashes.length >= this.sizeLimit 
				|| (clearList && this.flashes.length === 1))
			{
				this.flashes.pop();
			}
		}
	},
	watch: {
		flashes: function(){
			let i = this.flashes.length - 1;
			if(i >= 0 && i < this.flashes.length){
				this.flashes[i].id = i
			}
			this.$emit('newFlashMessage',this.flashes);
		}
	}
});

NotificationBus.$on('successMessage', (options) => {
	NotificationBus.popOnSizeLimit(options.clearList);
	
	let flash = {
		id: 0,
		message: options.message,
		type: 'success',
		autoClose: options.autoClose,
		clearList: options.clearList
	};
	NotificationBus.flashes.unshift(flash);
});

NotificationBus.$on('dangerMessage', (options) => {
	NotificationBus.popOnSizeLimit(options.clearList);

	let flash = {
		id: 0,
		message: options.message,
		type: 'danger',
		autoClose: options.autoClose,
		clearList: options.clearList
	};
	NotificationBus.flashes.unshift(flash);

});

NotificationBus.$on('eventFlashMessage', (flash) => {
	NotificationBus.popOnSizeLimit(flash.clearList);

	NotificationBus.flashes.unshift(flash);

	if(flash.autoClose){
		setTimeout(function(){
			NotificationBus.$emit('flashMessageClosed', flash.id);
		}, 5000);
	}
});

NotificationBus.$on('flashMessageClosed', (flashId) => {
	let index = NotificationBus.flashes.findIndex(f => f.id === flashId);
	NotificationBus.flashes.splice(index, 1);
	NotificationBus.flashes.forEach(function(f){
		f.id = (f.id >= flashId) ? (f.id - 1) : f.id;
	});
});

NotificationBus.$on('clearFlashMessages', () => {
	NotificationBus.flashes = [];
})
