<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12" style="text-align: center;">
        <h1>404</h1>
        <h2>Sorry, we could not find what you are looking for.</h2>
        <h1>:(</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound404"
}
</script>

<style scoped>

</style>