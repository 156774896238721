export default{
	mounted(){
		this.path = this.picturePath;
		this.size.width = this.pictureSize[0];
		this.size.height = this.pictureSize[1];
	},
	data(){
		return{
			path: '',
			size: {
				width: 0,
				height: 0
			},
			maxWidth: 300,
			maxHeight: 200
		}
	},
	props: ['picturePath', 'pictureSize'],
	// computed: {
	// 	setSizeOnLoad: function(){
	// 	}
	// },
	methods: {
		setSize: function(){
			if(this.size.height > 0){
				var aspectRatio = this.size.width / this.size.height;
			}
			var style = '';
			if(aspectRatio < 1){
				style = 'height:' + this.maxHeight + 'px;'
						+'width:' + (this.maxWidth * aspectRatio) + 'px;';
			}
			else if(aspectRatio > 1){
				style = 'width:' + this.maxWidth + 'px;'
						+'height:' + (this.maxHeight * aspectRatio) + 'px';
			}
			else{
				style = 'width:' + this.maxHeight + 'px;'
						+'height:' + this.maxHeight + 'px';	
			}
			//console.log(style);
			// $('#profile-view-picture-panel').attr('style', style);
			return style;
		}
	}
}