'use strict'
// import NotificationBus from '@/notification-bus.js';
// import InputError from '@/models/InputError.js';
// import GlobalEventBus from '@/event-bus.js';

export default {
    mounted () {
        this.verifyEmail();
    },
    data () {
        return {
            verifying: 0
        }
    },
    props: ['emailVerificationToken'],
    methods: {
        verifyEmail: function() {
            let url = this.$apiUrl + '/verifyEmail';
            let options = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            };
            let data = { email_validation_token: this.emailVerificationToken }
            this.$http.post(url, {data: data}, options).then(res => {
                if(res.body.succeeded) {
                    this.verifying = 2;
                    setTimeout(() => { this.$router.push('/login-view') }, 2000)

                } else {
                    this.verifying = 1;
                }
            });
        }
    }
}