import GlobalEventBus from '@/event-bus.js';
import NotificationBus from '@/notification-bus.js';
import downloadAnchor from './downloadAnchor.vue';
// import Vue from 'vue';

export default {
    mounted() {
        GlobalEventBus.$on('logedOut', () => {
            this.isGuest = true;
        });

    },
    components: {downloadAnchor},
    props: ['invoice', 'isGuestProp'],
    data() {
        return {
            authCode: this.$getCookieValue('authCode'),
            isGuest: this.isGuestProp,
            downloadUrl: "data:application/pdf;base64,",
            isSaving: false,
            isDownloading: false,
            isPreviewing: false,
            isSavingAsTemplate: false,
            errors: {
                invoice: {},
                hasErrors: false
            },
            actionsEnum: Object.freeze({
                preview: 0,
                download: 1,
                template: 2
            })
        }
    },

    methods: {
        /**
         * Generates a PDF file and presents it in a new tab
         * @return void
         */
        createPdf: function () {
            // this.validate();
            // console.log(this.errors.invoice)
            if (!this.errors.hasErrors) {
                const url = this.getCreatePdfUrl();
                this.isPreviewing = true;
                // console.log(this.invoice.comments)
                this.$http.post(url, {
                    invoice: JSON.stringify(this.invoice),
                    language: this.$getCookieValue('lang')
                }, {
                    headers: {
                        // 'Authorization': 'Bearer ' + this.authCode,
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf;application/octet-stream',
                        'Access-Control-Allow-Origin': '*'
                    }
                }).then(succeeded => {
                    if (succeeded.body.original !== undefined && succeeded.body.original.code === -1) {
                        NotificationBus.$emit('eventFlashMessage', {
                            id: 0,
                            message: 'Failed while creating the PDF file.',
                            type: 'danger',
                            autoClose: false
                        });
                        this.isPreviewing = false;
                    } else {
                        const dataURL = "data:application/pdf;base64," + succeeded.body.base64;
                        // let file = this.$dataURLtoFile(dataURL);
                        // file.name = 'invoice.pdf';

                        const w = window.open('_blank');
                        w.document.open();
                        w.document.title = succeeded.body.filename;
                        w.document.write(
                            `<object data="${dataURL}" 
                                     type="application/pdf" 
                                     style="width:100%;height:100vh"
                                     name="${succeeded.body.filename}"></object>`)
                        w.document.close();
                        w.document.body.style.margin = '0';
                        this.isPreviewing = false;
                    }
                }, () => {
                    NotificationBus.$emit('eventFlashMessage', {
                        id: 0,
                        message: 'Failed while creating the PDF file.',
                        type: 'danger',
                        autoClose: false
                    });
                    this.isPreviewing = false;
                });
            }
        },

        /**
         * Saves the invoice in the DB
         * @return void
         */
        save: function () {
            if (this.isGuestProp)
                window.location.replace('/invoice/details/guest');
            this.$http.post(this.$apiUrl + '/invoice/create', this.invoice, {
                headers: {
                    'Authorization': 'Bearer ' + this.authCode,
                    'Content-Type': 'application/json;multipart/form-data',
                    'Accept': '*/*'
                }
            }).then(succeded => {
                    // console.log(succeded.body);
                    this.$emit('eventInvoiceCreated', succeded.body.data.invoiceId);
                    if (succeded.body.succeded) {
                        // window.location.replace(succeded.body.data.redirectUrl);
                        //console.log(succeded.body.data.redirectUrl)
                    }
                    NotificationBus.$emit('eventFlashMessage', {
                        id: 0,
                        message: succeded.body.message,
                        type: 'success',
                        autoClose: true
                    });
                }, () => {
                    NotificationBus.$emit('eventFlashMessage', {
                        id: 0,
                        message: 'Failed while creating the PDF file.',
                        type: 'danger',
                        autoClose: false
                    });
                }
            );
        },

        /**
         * Downloads PDF
         * @return void
         */
        download: function () {
            const url = this.getCreatePdfUrl();
            this.isDownloading = true;
            this.$http.post(url, {invoice: JSON.stringify(this.invoice)}, {
                headers: {
                    'Authorization': 'Bearer ' + this.authCode,
                    'Content-Type': 'application/json;multipart/form-data',
                    'Accept': 'application/pdf;application/octet-stream'
                }
            }).then(succeded => {
                this.downloadUrl += succeded.body.base64;
                const dateStr = succeded.body.fileName;

                let body = document.getElementsByTagName('body')[0];
                let anchor = document.createElement('a');
                anchor.setAttribute('id', 'downloader');
                anchor.setAttribute('target', '_blank');
                anchor.setAttribute('href', this.downloadUrl);
                anchor.setAttribute('download', dateStr);

                body.append(anchor);

                let a = document.getElementById("downloader");
                let mEvent = new MouseEvent("click", {bubbles: false, cancelable: true, view: window});

                a.dispatchEvent(mEvent);
                a.remove();

                this.isDownloading = false;
            }, () => {
                NotificationBus.$emit('eventFlashMessage', {
                    id: 0,
                    message: 'Failed while creating the PDF file.',
                    type: 'danger',
                    autoClose: true
                });
                this.isDownloading = false;
            });
        },

        /**
         * El titulo de los documentos en base a la fecha
         * y el tiempo actual
         * @return string
         */
        getInvoiceTitle: function () {
            const date = new Date();
            return date.getFullYear().toString() + '-'
                + (date.getMonth() + 1).toString().padStart(2, '0') + '-'
                + (date.getDate()).toString().padStart(2, '0') + '_'
                + (date.getHours()).toString().padStart(2, '0') +
                +(date.getMinutes()).toString().padStart(2, '0') +
                +(date.getSeconds()).toString().padStart(2, '0');
        },

        /**
         * Saves template
         * @return {[type]} [description]
         */
        saveTemplate: function () {
            const url = '/invoice/create';
            this.$http.post(url, {invoice: JSON.stringify(this.invoice)}, {
                headers: {
                    'Authorization': 'Bearer ' + this.authCode,
                    'Content-Type': 'application/json;multipart/form-data',
                    'Accept': 'application/pdf;application/octet-stream'
                }
            }).then(() => {
                NotificationBus.$emit('eventFlashMessage', {
                    id: 0,
                    message: 'INVOICE.SAVED',
                    type: 'success',
                    autoClose: true
                });
            }, () => {
                NotificationBus.$emit('eventFlashMessage', {
                    id: 0,
                    message: 'Failed while creating the PDF file.',
                    type: 'danger',
                    autoClose: true
                });
                this.isDownloading = false;
            });
        },

        /**
         * Clears all the invoice
         * @return void
         */
        clearAll: function () {
            GlobalEventBus.$emit('onClearAllClicked', {});
        },

        /**
         * Elige si debe de utilizar la ruta para miembros o la de usuraios gratis
         * @return string
         */
        getCreatePdfUrl: function () {
            let apiUrl = this.$apiUrl;
            return (this.isGuest)
                ? apiUrl + "/invoice/createpdffree"
                : apiUrl + "/invoice/createpdffree";
        },

        /**
         * Deside si debe de estar en estado desbilitado dependiendo
         * de la bandera que se pasó como parámetro
         * @param  boolean flag
         * @return Object
         */
        disabler: function (flag) {
            return {
                'disabled': flag
            }
        },

        /**
         * Decide si el buton debe de presentar la animación de girar
         * o el ícono segun el valor de {action}
         * @param  boolean flag
         * @param  number  action
         * @return string
         */
        spin: function (flag, action) {
            if (flag) return 'fa-spinner fa-spin'

            switch (action) {
                // case 0:
                // 	return 'fa-file-pdf-o'
                case this.actionsEnum.download:
                    return 'fa-download'
                case this.actionsEnum.template:
                    return 'fa-floppy-o'
            }
        },

        /**
         * @return {void}
         */
        validate: function () {
            this.errors.invoice.number = {'hasError': false, 'message': ''};
            this.errors.invoice.billdate = {'hasError': false, 'message': ''};
            this.errors.invoice.products = {'hasError': false, 'message': ''};
            this.errors.invoice.tasks = {'hasError': false, 'message': ''};
            this.errors.invoice.total = {'hasError': false, 'message': ''};

            if (this.$isEmptyString(this.invoice.number)) {
                this.errors.invoice.number = {
                    'hasError': true,
                    'message': 'The invoice number is requiered'
                };
            }
            let message = '';
            if (this.$isEmptyString(this.invoice.billdate)) {
                message = 'This field is required.';
            } else if (isNaN(this.invoice.billdate)) // Invalid Date
            {
                message = 'This date id invalid.';
            }
            this.errors.invoice.billdate = {'hasError': true, 'message': message}

            if (this.invoice.products.length == 0) {
                message = 'Include at least 1 product.';
            }
            this.errors.invoice.products = {'hasError': true, 'message': message}

            if (this.invoice.tasks.length == 0) {
                message = 'Include at least 1 task.';
            }
            this.errors.invoice.tasks = {'hasError': true, 'message': message}

            if (this.$isEmptyString(this.invoice.total)) {
                message = 'This field is required.';
            } else if (isNaN(this.invoice.total)) {
                message = 'Total is not a valid number.';
            }
            this.errors.invoice.total = {'hasError': true, 'message': message}

            this.errors.hasErrors = Object.keys(this.errors.invoice)
                .map(o => this.errors.invoice[o].hasError)
                .includes(true);

            this.$emit('onValidated', this.errors);
        }
    },
    computed: {
        setPreviewButtonIcon: function () {
            return {
                'fa fa-spin fa-spinner': this.isPreviewing,
                'fa fa-file-pdf-o': !this.isPreviewing
            }
        }
    }
}