export default class Task
{
	constructor (id = 0, rate = null, title = '', timeStr = '', value = 0, total = 0.00)
	{
		this.id = id;
		this.rate = rate;
		this.title = title;
		this.timeStr = timeStr;
		this.value = value;
		this.total = total;
	}
}