import NotificationBus from '@/notification-bus.js';

export default{
	props: ['rowProp', 'subTotal', 'globalRateFlag'],
	data(){
		return {
			oldTaskRate: 0.00,
			oldTaskHoursWorked: 0.00,
			oldTaskTotal: 0.00,
			newTaskTotal: 0.00,
			newTaskHoursWorked: 0.00,
			newTaskRate: 0.00,
			newSubTotalValue: 0.00,
			rateTmp: 0.00,
			taskHoursWorkedError: false,
			row: {}
		}
	},
	mounted(){
		// this.rowProp = this.rowProp
		this.row = this.rowProp
	},
	methods:{
		getOldValues: function(){
			if(this.oldTaskHoursWorked) this.setHorsWorked();
			if (this.row.rate === null) this.row.rate = '';
			this.oldTaskRate = (this.row.rate === '') ? 0.00 : this.row.rate;
			this.oldTaskTotal = parseFloat(this.oldTaskRate * this.oldTaskHoursWorked)
			// console.log('this.oldTaskTotal', this.oldTaskTotal)
		},
		sendNewValues: function(){
			if (this.row.rate === null) this.row.rate = '';
			this.rateTmp = (this.row.rate === '') ? 0.00 : this.row.rate;
			this.newTaskHoursWorked = parseFloat(this.row.value);
			this.newTaskRate = parseFloat(this.rateTmp);
			this.newTaskTotal = parseFloat(this.newTaskRate * this.newTaskHoursWorked);
			this.newSubTotalValue = parseFloat((this.subTotal - this.oldTaskTotal) + this.newTaskTotal);
			this.getOldHoursWorked();
			this.row.total = this.newTaskTotal;
			if(!isNaN(this.newSubTotalValue))
				this.$emit('eventChangeSubTotal', this.newSubTotalValue);
		},
		getOldHoursWorked: function(){
			this.getOldValues()
			this.setHorsWorked();
			this.oldTaskHoursWorked = this.row.value;

		},
		setNewHoursWorked: function(){
			this.getOldHoursWorked();
			this.setHorsWorked();
			this.sendNewValues();
		},
		removeRow: function(){
			if (this.row.rate === null) this.row.rate = '';
			this.rateTmp = (this.row.rate === '') ? 0.00 : this.row.rate;
			this.newSubTotalValue = this.subTotal - (this.rateTmp * this.row.value);
			let data = {
				taskId: this.row.id,
				newSubTotal: parseFloat(this.newSubTotalValue)
			};
			if(isNaN(data.newSubTotal))data.newSubTotal = 0.00
			this.$emit("eventClickRemoveTaskRow", data);
		},
		setHorsWorked: function(){
			if(this.row.timeStr.includes(':')){
				let tmp = this.row.timeStr.split(':');
				let h = parseFloat(tmp[0] * 1);

				if(tmp[1].includes('.') || isNaN(tmp[1])) {
					this.taskHoursWorkedError = true;

					NotificationBus.$emit('eventFlashMessage',{
						id: 0,
						message: `Error in the hours field in tasks row number ${this.row.id + 1}.`,
						type: 'danger',
						autoClose: false
					});
				}
				else {
					this.taskHoursWorkedError = false;

					if(tmp[1].length > 2){
						tmp[1] = parseFloat(tmp[1].substring(0, 2));
					}
					if(tmp[1].length === 1){
						h = parseFloat(h) + parseFloat( (parseInt(tmp[1] * 1) * 10) / 60);
					}
					else {
						h = parseFloat(h) + parseFloat(parseInt(tmp[1] * 1) / 60);
					}
					this.row.value = h;
				}
			}
			else{
				if(this.row.timeStr.length === 0)
					this.row.value =  0;
				else if(!isNaN(this.row.timeStr))
					this.row.value = parseFloat(this.row.timeStr).toFixed(2)
			}
		},
		listnerNewTaskTotal: function(){
			//console.log('listener', data)
		}
	},
	computed: {
		getRowTotal: function(){
			return (this.newTaskHoursWorked * this.newTaskRate).toFixed(2);
			// this.row.total = (this.newTaskHoursWorked * this.newTaskRate).toFixed(2);
		}
	}
}